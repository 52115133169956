import React from "react"
import { graphql, Link } from "gatsby"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Image from "../../../components/image"
import Layout, { athenaTheme } from "../../../components/layout"
import MoreArticles from "../../../components/athena/moreArticles.js"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import SingleImage from "../../../components/zeus-modules/singleImage"

import styles from "./octopuses.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
}

function SideBySide(props) {
  return (
    <div className={styles.sbs}>
      <div className="wrapper">
        <div className={`columns is-vcentered is-tablet ${styles.columns}`}>
          <div className="column is-4-tablet is-3-desktop is-offset-1-desktop">
            <div className={styles.sbsImage}>
              <Image
                width={props.image.width}
                height={props.image.height}
                sizes="(min-width: 1424px) 306px, (min-width: 1024px) calc((100vw - 5rem)/4), (min-width: 768px) calc((100vw - 5rem)/3), 180px"
                url={props.image.url}
                alt={props.image.altText}
                htmlAttributes={{
                  role: "presentation",
                }}
              />
            </div>
          </div>
          <div className="column is-7-tablet is-6-desktop is-offset-1-desktop">
            <div className={styles.sbsHeading}>
              <h3 className="a-h-2 italic fw-light">{props.title}</h3>
            </div>
            <div className={styles.sbsBody}>
              <div className="user-content-athena">{props.children}</div>
            </div>
            <div className={styles.sbsTakeaway}>
              <div className={styles.takeaway}>Company Takeaway</div>
              {props.takeaway}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const coverImages = article.coverImages ? article.coverImages[0] : null
  const athenaCta = article.athenaCta
  const fluidBrandUri = data.craft.fluidBrand.uri

  // Assets
  const effectiveness = data.craft.effectiveness[0]
  const quote = data.craft.quote[0]
  const dooDad1 = data.craft.dooDad1[0]
  const dooDad2 = data.craft.dooDad2[0]
  const dooDad3 = data.craft.dooDad3[0]
  const dooDad4 = data.craft.dooDad4[0]
  const dooDad5 = data.craft.dooDad5[0]
  const dooDad6 = data.craft.dooDad6[0]
  const titleFormed = data.craft.titleFormed[0]
  const titleFluid = data.craft.titleFluid[0]
  const takeaway1 = data.craft.takeaway1[0]
  const takeaway2 = data.craft.takeaway2[0]
  const takeaway3 = data.craft.takeaway3[0]
  const takeaway4 = data.craft.takeaway4[0]
  const takeaway5 = data.craft.takeaway5[0]
  const takeawayMobile1 = data.craft.takeawayMobile1[0]
  const takeawayMobile2 = data.craft.takeawayMobile2[0]
  const takeawayMobile3 = data.craft.takeawayMobile3[0]
  const takeawayMobile4 = data.craft.takeawayMobile4[0]
  const takeawayMobile5 = data.craft.takeawayMobile5[0]

  let sectionTitles = [
    "The company is the employees",
    "Always interact with your environment",
    "Building capabilities, not skills",
    "Transferring knowledge",
    "Faster progress, shorter lifespan",
  ]
  let [activeSectionTitle, setActiveSectionTitle] = React.useState(0)

  let sectionsRef = React.useRef()
  let sectionVisibility = React.useRef(new Map())
  React.useEffect(() => {
    let node = sectionsRef.current
    if (node == null) return

    let sections = [].slice.call(node.querySelectorAll("." + styles.section))

    let observer = new IntersectionObserver(
      function callback(entries, observer) {
        entries.forEach((entry, i) => {
          if (entry.isIntersecting) {
            sectionVisibility.current.set(
              entry.target.dataset.number,
              entry.intersectionRatio
            )
          } else {
            sectionVisibility.current.set(entry.target.dataset.number, -1)
          }
        })
        let ratios = Array.from(sectionVisibility.current.entries())
        if (ratios.length === 0) return
        let highest = ratios.reduce((max, entry) => {
          if (entry[1] > max[1]) {
            return entry
          }
          return max
        })
        if (highest[1] > 0) {
          setActiveSectionTitle(parseInt(highest[0], 10))
        }
      },
      {
        rootMargin: "0% 0px -10% 0px", // This compensates for the `top: 10vh`
        threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      }
    )

    sections.forEach((section, i) => {
      section.dataset.number = i
      observer.observe(section)
    })

    return () => {
      observer.disconnect()
    }
  }, [sectionsRef])

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody}>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      Today, people want real change from brands. They want
                      companies to match the rapid pace of culture with both{" "}
                      <Link to={`/${fluidBrandUri}`}>
                        material and aesthetic evolutions
                      </Link>
                      . The companies who want to succeed must be
                      hyper-adaptive, interactive and progressive. To meet this
                      need for newness they must be fluid.
                    </p>
                    <p>
                      But how can a company — inherently a structure — be fluid?
                      On its face, it seems like the ideas are incongruent. If
                      operationalizing is the act of running something in a
                      repeatable and efficient way, how can you herd fluidity
                      into a formula? How do you put improvisation into action?
                    </p>
                    <p>
                      Many of our models for operational structures take cues
                      from the natural world. We look at humans and other
                      vertebrates to show how rigid, strong structures work best
                      and why. But our physical form is only half (or less) of
                      the story. We do brilliant creative work with our minds,
                      but our physical form often limits what our consciousness
                      can actually realize.
                    </p>
                  </div>
                </div>
              </div>

              <div className="columns is-tablet">
                <div className="column is-10-tablet is-8-desktop is-offset-1-desktop is-offset-1-tablet">
                  <blockquote className={styles.quote}>
                    <p>
                      <span className="italic">But how can a company</span> —
                      inherently a structure —{" "}
                      <span className="italic">be&nbsp;fluid?</span>
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop user-content-athena">
                  <p>
                    Unsurprisingly, that same behavior is reflected in companies
                    — one part is able to conceive radical change, but it’s
                    braked by the majority, which decides whether the thought is
                    manifested into action. For a fluid model, we need to start
                    someplace else.
                  </p>
                  <p>
                    What about organisms that aren’t built around rigid
                    skeletons? Invertebrates.
                  </p>
                  <p>
                    What about ones that can change shape and adapt?
                    Cephalopods.
                  </p>
                  <p>
                    What about cephalopods that are highly capable and operate
                    with a high degree of intelligence? Octopuses.
                  </p>
                  <p>
                    In many cases, octopuses exemplify operationalized fluidity
                    in the natural world; they’re adaptive, strong, efficient
                    and always what they need to be in that exact moment.
                    There’s much to still be learned about these awe-inspiring
                    creatures, but we can take inspiration now to create our own
                    fluid operation.
                  </p>
                </div>
              </div>
            </div>
            {/* Img-Quote */}
            <div className="wrapper wrapper--full-bleed">
              <div className={styles.imgQuote}>
                <div className={styles.imgQuoteImg}>
                  <Image
                    width={quote.width}
                    height={quote.height}
                    sizes="(min-width: 1800px) 828px, (min-width: 1216px) 46vw, (min-width: 1024px) 60vw, (min-width: 768px) calc(100vw - 5rem), calc(100vw - 2.5rem)"
                    url={quote.url}
                    alt={quote.altText}
                    htmlAttributes={{
                      role: "presentation",
                    }}
                  />
                </div>
                <div className={styles.imgQuoteContent}>
                  <div className="wrapper">
                    <div className="columns is-desktop">
                      <div className="column is-9-desktop">
                        <blockquote>
                          <p
                            className={`a-h-1 fw-light ${styles.imgQuoteQuote}`}
                          >
                            <span className="italic">
                              It has a body — but one that is protean, all
                              possibility; it has none of the costs and gains of
                              a constraining and action-guiding body.
                            </span>{" "}
                            The octopus lives outside the usual body/brain
                            divide.
                          </p>
                          <cite className="subhead subhead--responsive fw-bold">
                            <div className="user-content-athena">
                              - Dr. Peter Godfrey-Smith.{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.scientificamerican.com/article/the-mind-of-an-octopus/"
                              >
                                Scientific American
                              </a>
                            </div>
                          </cite>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.sectionHeading}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <h2 className="a-h-2 fw-light text-center">
                      What can I learn from the&nbsp;octopus?
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.sbsList}>
              <SideBySide
                image={dooDad1}
                title="They are their brain"
                takeaway="The company is the employees."
              >
                <ul>
                  <li>
                    “It is not clear where the brain itself begins and ends. The
                    octopus is suffused with nervousness; the body is not a
                    separate thing that is controlled by the brain or nervous
                    system.”{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.scientificamerican.com/article/the-mind-of-an-octopus/"
                    >
                      Scientific American
                    </a>
                    .
                  </li>
                  <li>
                    Put another way, there’s (likely) no obvious boundary
                    between the physical and cognitive experience for octopuses
                  </li>
                </ul>
              </SideBySide>

              <SideBySide
                image={dooDad2}
                title="They are highly interactive with their environment"
                takeaway="Always be interacting."
              >
                <ul>
                  <li>
                    Octopuses are constantly exploring, touching, tasting and
                    playing with their arms
                  </li>
                  <li>
                    They change size, color and shape to be most relevant in
                    their environment
                  </li>
                </ul>
              </SideBySide>

              <SideBySide
                image={dooDad3}
                title="They learn quickly and try different ways to accomplish tasks that include their whole body"
                takeaway="Create, experiment and learn how to be engaged in your environment."
              >
                <ul>
                  <li>
                    Hyper-adaptive and incredible problem-solving capabilities
                    used beyond survival basics
                  </li>
                  <li>
                    Can use various tools, including their entire body, to
                    accomplish a task
                  </li>
                  <li>
                    They don’t rely on fixed responses to survive — they can
                    invent new behaviors{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.nytimes.com/2018/11/30/science/animal-intelligence-octopus-cephalopods.html"
                    >
                      on the fly
                    </a>
                  </li>
                </ul>
              </SideBySide>

              <SideBySide
                image={dooDad4}
                title="They practice a mixture of localized and top-down control"
                takeaway="Give employees agency and autonomy. "
              >
                <ul>
                  <li>
                    Famously, each arm is “independent” — it doesn’t need the
                    central brain in order to make decisions or complete tasks
                  </li>
                  <li>
                    Each arm has its own “mind,” but they can also work with and
                    complete tasks for the central brain
                  </li>
                </ul>
              </SideBySide>

              <SideBySide
                image={dooDad5}
                title="They are experts in transferring knowledge"
                takeaway="Connect everyone — create open access to information."
              >
                <ul>
                  <li>
                    Each arm shares knowledge with both the central brain — and
                    the rest of the arms
                  </li>
                  <li>
                    Vast majority of their neurons concentrated at the front of
                    the arms, not deep within in the central brain
                  </li>
                </ul>
              </SideBySide>

              <SideBySide
                image={dooDad6}
                title="Death and regrowth is normal"
                takeaway="Don’t be precious about the past."
              >
                <ul>
                  <li>
                    Can lose and gain new appendages without hurting the overall
                    body
                  </li>
                  <li>Their lifespan is relatively short</li>
                </ul>
              </SideBySide>
            </div>
            <div className={styles.singleImage}>
              <SingleImage
                data={{
                  image: [effectiveness],
                  mobileImage: [effectiveness],
                  columnWidth: "fullBleed",
                }}
              />
            </div>

            <div className={styles.sectionHeading}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-8-tablet is-6-desktop">
                    <h2 className="a-h-2 fw-light">
                      Cool. So what does that look like more specifically? And
                      what does this mean for operational effectiveness?
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            {/* Sections */}
            <div className={styles.sections} ref={sectionsRef}>
              <div className="wrapper">
                <div className="columns is-tablet">
                  <div
                    className={`column is-5-tablet is-6-desktop ${styles.floatingColumn}`}
                  >
                    <div className={styles.floatingSection}>
                      <div className={styles.floatingSectionInner}>
                        <div className={styles.floatingSectionShapes}>
                          <MorphingSectionShapes
                            shapeIndex={activeSectionTitle}
                            shapeConfigs={[
                              {
                                color: "#50A173",
                                photo: takeaway1,
                                transforms: {
                                  color: "translate(-86px, 22px) scale(0.55)",
                                  photo: "translate(-105px, -64px) scale(0.65)",
                                },
                              },
                              {
                                color: "#E8ABCD",
                                photo: takeaway2,
                                transforms: {
                                  color:
                                    "translate(-110px, 20px) scale(0.6) rotate(15deg)",
                                  photo:
                                    "translate(-100px, -80px) scale(0.6) rotate(20deg)",
                                },
                              },
                              {
                                color: "#5D7EBE",
                                photo: takeaway3,
                                transforms: {
                                  color: "translate(-90px, -30px) scale(0.6)",
                                  photo: "translate(-100px, -110px) scale(0.6)",
                                },
                              },
                              {
                                color: "#E8ABCD",
                                photo: takeaway4,
                                transforms: {
                                  color:
                                    "translate(-111px, 21px) scale(0.6) rotate(-12deg)",
                                  photo:
                                    "translate(-30px, -90px) scale(0.6) rotate(-12deg)",
                                },
                              },
                              {
                                color: "#F1B648",
                                photo: takeaway5,
                                transforms: {
                                  color:
                                    "translate(-101px, 121px) scale(0.6) rotate(18deg)",
                                  photo:
                                    "translate(-91px, 1px) scale(0.6) rotate(14deg)",
                                },
                              },
                            ]}
                          />
                        </div>
                        <div className={styles.floatingSectionTitle}>
                          <span className="fw-bold">{`0${activeSectionTitle +
                            1} -`}</span>
                          <h3 className={styles.floatingHeading}>
                            {sectionTitles[activeSectionTitle]}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column is-7-tablet is-6-desktop">
                    <div className={styles.section}>
                      <div className={styles.sectionMobileTitle}>
                        <div className={styles.sectionMobileTitleImg}>
                          <Image
                            width={takeawayMobile1.width}
                            height={takeawayMobile1.height}
                            sizes="220px"
                            url={takeawayMobile1.url}
                            alt={takeawayMobile1.altText}
                          />
                        </div>
                        <div className={styles.sectionMobileTitleText}>
                          <span className="fw-bold">01 —</span>
                          <h3 className="a-h-1 serif-italic">
                            The company is the employees
                          </h3>
                        </div>
                      </div>
                      <div className="user-content-athena">
                        <p>
                          The fluid company is the ambition and progress of the
                          employees. There is a fluid, dynamic and equitable
                          relationship between employees in order to be as
                          adaptable to the environment as possible. Employees
                          can provide specific services, but they must all be on
                          the same playing field and share the same larger
                          ambition. There can’t be a decision-making body that
                          controls everything and then relays to order-takers.
                        </p>
                        <p>
                          This also is a shift in thinking from “efficiency can
                          only be derived from everyone having discrete tasks
                          and a unified process” to “efficiency is gained by
                          collectively getting further, faster.” In fluid
                          companies, the goal is to get everyone progressing
                          towards the same destination at the same pace but not
                          necessarily using the exact same path.
                        </p>
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={styles.sectionMobileTitle}>
                        <div className={styles.sectionMobileTitleImg}>
                          <Image
                            width={takeawayMobile2.width}
                            height={takeawayMobile2.height}
                            sizes="220px"
                            url={takeawayMobile2.url}
                            alt={takeawayMobile2.altText}
                          />
                        </div>
                        <div className={styles.sectionMobileTitleText}>
                          <span className="fw-bold">02 —</span>
                          <h3 className="a-h-1 serif-italic">
                            Always interact with your environ&shy;ment
                          </h3>
                        </div>
                      </div>
                      <div className="user-content-athena">
                        <p>
                          The fluid company is constantly trying to adapt,
                          change and be more relevant within culture. Since the
                          company is its employees, their individual and
                          collective creativity — both in approach and output —
                          is a must. They must be responsible for constantly
                          interacting with their environment and deciding how to
                          solve the problem at hand in that context. By virtue
                          of constantly interacting with culture, there will be
                          new inputs and changing contexts that force
                          experimentation in approach.
                        </p>
                        <p>
                          However, creating positive feedback loops for
                          experimentation will ensure there is not a regression
                          to standardized processes. For fluid companies, this
                          variation is integral for survival and therefore
                          should be prioritized in workflows and incentives.
                        </p>
                        <p>
                          To be clear, the goal isn’t chaos, random outputs or
                          new and different for new and different’s sake. It’s
                          all in service of accomplishing a goal. And the set of
                          approaches, tools and expertises that are combined
                          need to be applicable and properly deployed in a given
                          environment.
                        </p>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <div className={styles.sectionMobileTitle}>
                        <div className={styles.sectionMobileTitleImg}>
                          <Image
                            width={takeawayMobile3.width}
                            height={takeawayMobile3.height}
                            sizes="220px"
                            url={takeawayMobile3.url}
                            alt={takeawayMobile3.altText}
                          />
                        </div>
                        <div className={styles.sectionMobileTitleText}>
                          <span className="fw-bold">03 —</span>
                          <h3 className="a-h-1 serif-italic">
                            Building capabilities, not skills
                          </h3>
                        </div>
                      </div>
                      <div className="user-content-athena">
                        <p>
                          In order to practice variation in problem-solving,
                          employees must be able to, well, solve problems. This
                          is not a hard skill or innate ability, but a
                          capability. Capabilities like inventiveness,
                          creativity, adaptivity and anticipation are necessary
                          for the fluid organization.
                        </p>
                        <p>
                          Capabilities are tools to solve many different
                          problems in many different ways, versus specifics
                          skills applied to specific problems. The development
                          and honing of them is a lifelong endeavor. They must
                          be learned, practiced and built on over time. While
                          they need investment and upkeep, they’ll pay you back
                          tenfold and are far more efficient in the long run
                          than learning situationally specific skills. And the
                          combination of these capabilities to solve a given
                          problem will result in more fluid and variated
                          solutions.
                        </p>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <div className={styles.sectionMobileTitle}>
                        <div className={styles.sectionMobileTitleImg}>
                          <Image
                            width={takeawayMobile4.width}
                            height={takeawayMobile4.height}
                            sizes="220px"
                            url={takeawayMobile4.url}
                            alt={takeawayMobile4.altText}
                          />
                        </div>
                        <div className={styles.sectionMobileTitleText}>
                          <span className="fw-bold">04 —</span>
                          <h3 className="a-h-1 serif-italic">
                            Transferring knowledge
                          </h3>
                        </div>
                      </div>
                      <div className="user-content-athena">
                        <p>
                          The cross-company sharing of these solutions and
                          associated knowledge will massively strengthen
                          everyone. What someone learns in any given situation
                          must be shared with the rest of the fluid organization
                          to maximize efficiency.
                        </p>
                        <p>
                          In smaller fluid organizations, knowledge can be
                          shared relatively easily. In larger ones, a place
                          where knowledge can be added to and then accessed is
                          key. By centralizing the knowledge of employees — both
                          expertises and approaches — there will be an
                          intellectual property brain that anyone in the company
                          can interact with, access and use. Employees can use
                          the pieces they need and employ them in a relevant way
                          to the context at hand.
                        </p>
                        <p>
                          Furthermore, the goal is not to just create a snapshot
                          of collective knowledge at a moment in time, but
                          rather create a living organism that is constantly
                          adding new knowledge, updating old knowledge and
                          shedding what is no longer useful. In order to build
                          this into the workflow, this would be maintained by
                          the entire organization, not just a singular keeper.
                        </p>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <div className={styles.sectionMobileTitle}>
                        <div className={styles.sectionMobileTitleImg}>
                          <Image
                            width={takeawayMobile5.width}
                            height={takeawayMobile5.height}
                            sizes="220px"
                            url={takeawayMobile5.url}
                            alt={takeawayMobile5.altText}
                          />
                        </div>
                        <div className={styles.sectionMobileTitleText}>
                          <span className="fw-bold">05 —</span>
                          <h3 className="a-h-1 serif-italic">
                            Faster progress, shorter lifespan
                          </h3>
                        </div>
                      </div>
                      <div className="user-content-athena">
                        <p>
                          All of the activities of a fluid company are happening
                          at a rapid pace — a constant dialogue with culture
                          demands such frenzied activity. This means the company
                          will be efficient by making more progress in a shorter
                          amount of time. Employees are not managing each other;
                          they’re making progress on their trajectory.
                          Observing, assessing, creating, deploying and
                          optimizing in any given context in order achieve a
                          tangible goal demands a lot energy. It means shedding
                          what is no longer relevant and useful. This speed is
                          key and efficient, but it’s not sustainable. The fluid
                          organization will die in its current form, but because
                          it’s constantly changing, growing, entering new spaces
                          and becoming something new.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .sections */}

            {/* Comparison */}
            <div className="wrapper">
              <div className={styles.comparison}>
                <div className="columns is-tablet">
                  <div
                    className={`column is-6-tablet is-5-desktop is-offset-1-desktop ${styles.comparisonColumn}`}
                  >
                    <h2 className="screen-reader-only">The Formed Company</h2>
                    <div className={styles.comparisonTitle}>
                      <Image
                        width={titleFormed.width}
                        height={titleFormed.height}
                        sizes="309px"
                        url={titleFormed.url}
                        alt={titleFormed.altText}
                        htmlAttributes={{
                          role: "presentation",
                        }}
                      />
                    </div>
                    <div className="user-content-athena">
                      <ul>
                        <li>Employees are in service of the company</li>
                        <li>Develop skills</li>
                        <li>Mirror culture</li>
                        <li>Efficiency derived from perfected consistency</li>
                        <li>Structured, hierarchical</li>
                        <li>Maximizes category market share</li>
                        <li>Consistent identity, minimal updates</li>
                        <li>Goal is to continue to exist</li>
                        <li>Company death results in company end</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className={`column is-6-tablet is-5-desktop is-offset-1-desktop ${styles.comparisonColumn}`}
                  >
                    <h2 className="screen-reader-only">The Fluid Company</h2>
                    <div className={styles.comparisonTitle}>
                      <Image
                        width={titleFluid.width}
                        height={titleFluid.height}
                        sizes="309px"
                        url={titleFluid.url}
                        alt={titleFluid.altText}
                        htmlAttributes={{
                          role: "presentation",
                        }}
                      />
                    </div>
                    <div className="user-content-athena">
                      <ul>
                        <li>Employees are the company</li>
                        <li>Develop capabilities</li>
                        <li>Interact with culture</li>
                        <li>Efficiency derived from velocity</li>
                        <li>Flat, autonomous</li>
                        <li>Creates new categories</li>
                        <li>Changing identity (brand and vision)</li>
                        <li>Goal is to accomplish vision</li>
                        <li>Company death results in new goal, new brand</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tldr}>
              <div className="wrapper">
                <div className="columns is-centered">
                  <div className="column is-8-tablet">
                    <div className="mb-7">
                      <h2 className="h-1 serif">
                        TLDR &mdash;
                        <br />{" "}
                        <span className="serif-italic">Octopuses rule</span>
                      </h2>
                    </div>
                    <div>
                      <p>
                        In a fluid company, efficiency is derived from a state
                        of rapid progress, not perfected consistency. This will
                        likely result in the company changing its identity and
                        infrastructure to support new identities, enter new
                        verticals and create new categories.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
            <MoreArticles id={article.id} issue={article.issue[0].id} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      fluidBrand: entry(slug: "the-fluid-brand") {
        uri
      }
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      dooDad1: assets(id: 3317) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      dooDad2: assets(id: 3318) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      dooDad3: assets(id: 3319) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      dooDad4: assets(id: 3320) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      dooDad5: assets(id: 3321) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      dooDad6: assets(id: 3322) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeaway1: assets(id: 3343) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeaway2: assets(id: 3344) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeaway3: assets(id: 3345) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeaway4: assets(id: 3346) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeaway5: assets(id: 3347) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeawayMobile1: assets(id: 3353) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeawayMobile2: assets(id: 3354) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeawayMobile3: assets(id: 3355) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeawayMobile4: assets(id: 3356) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      takeawayMobile5: assets(id: 3357) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      effectiveness: assets(id: 3334) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      quote: assets(id: 3335) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      titleFormed: assets(id: 3342) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      titleFluid: assets(id: 3341) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`

function MorphingSectionShapes({ shapeIndex, shapeConfigs }) {
  React.useEffect(() => {
    let photoAnimation = document.getElementById(`to-${shapeIndex + 1}A`)
    let colorAnimation = document.getElementById(`to-${shapeIndex + 1}B`)

    // Check if beginElement is supported before using it
    if ("beginElement" in photoAnimation) {
      photoAnimation.beginElement()
      colorAnimation.beginElement()
    }

    for (let i = 0; i < shapeConfigs.length; i++) {
      let el = document.getElementById(`takeaway-photo-${i + 1}`)
      if (el != null) {
        el.style.opacity = 0
      }
    }
    let el = document.getElementById(`takeaway-photo-${shapeIndex + 1}`)
    if (el != null) {
      el.style.opacity = 1
    }
  }, [shapeIndex, shapeConfigs.length])
  return (
    <svg
      role="presentation"
      width="652px"
      height="652px"
      viewBox="0 0 652 652"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="photo-clip">
          <polygon
            id="1A"
            points="196.308642 0.664967 158.288642 58.817967 130.052642 102.007967 113.465642 156.991967 70.8896419 217.469967 24.5296419 319.503967 0.75364185 361.557967 68.0276419 409.746967 125.758642 444.098967 211.639642 456.980967 260.304642 473.680967 318.362642 456.862967 392.943642 345.812967 463.783642 204.614967 481.753642 172.107967 434.265642 145.855967 402.485642 105.347967 341.414642 76.719967 262.468642 41.786967 217.701642 20.070967"
          >
            <animate
              id="to-1A"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="196.308642 0.664967 158.288642 58.817967 130.052642 102.007967 113.465642 156.991967 70.8896419 217.469967 24.5296419 319.503967 0.75364185 361.557967 68.0276419 409.746967 125.758642 444.098967 211.639642 456.980967 260.304642 473.680967 318.362642 456.862967 392.943642 345.812967 463.783642 204.614967 481.753642 172.107967 434.265642 145.855967 402.485642 105.347967 341.414642 76.719967 262.468642 41.786967 217.701642 20.070967"
            />
            <animate
              id="to-2A"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="419.345197 0.0919123 327.863197 13.9039123 286.738197 20.1129123 201.972197 32.9119123 90.8911972 41.9959123 27.4841972 54.1319123 0.97119715 88.8849123 27.0711972 261.755912 37.9151972 333.578912 47.1201972 394.541912 89.4271972 418.903912 191.820197 403.443912 235.461197 396.854912 289.666197 370.382912 326.599197 352.344912 387.867197 343.094912 459.438197 311.088912 445.115197 193.513912 432.106197 92.9069123 424.641197 35.1669123"
            />
            <animate
              id="to-3A"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="373.338 0 83.158 12.972 38.8 12.972 0 12.875 12.924 113.499 12.924 177.275 12.924 225.916 12.924 275.639 11.436 336.887 16.62 414 216.234 408.596 280.924 408.596 360.4 414 414 411.838 414 352.387 404.759 305.906 404.759 208.621 386.276 162.14 382.579 97.285 378.883 30.267"
            />
            <animate
              id="to-4A"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="189.255592 0.253066876 73.788592 16.8580669 34.464592 16.8580669 0.0705919621 16.7560669 11.527592 123.527067 11.527592 191.198067 0.0705919621 247.573067 11.527592 295.573067 14.803592 442.385067 183.053592 447.253067 249.101592 436.650067 319.555592 442.385067 367.070592 440.091067 367.070592 377.007067 358.877592 327.688067 358.877592 224.460067 342.493592 175.140067 339.216592 106.322067 335.939592 35.2090669 331.024592 3.09406688"
            />
            <animate
              id="to-5A"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="189.185 4.54747351e-13 73.718 16.605 34.394 16.605 0 16.503 11.457 123.274 11.457 190.945 0 247.32 11.457 295.32 10.137 360.308 14.733 442.132 182.983 447 249.031 436.397 319.485 442.132 367 439.838 367 376.754 358.807 327.435 358.807 224.207 342.423 174.887 339.146 106.069 330.954 2.841"
            />
          </polygon>
        </clipPath>
        <clipPath id="color-clip">
          <polygon
            id="1B"
            points="427.31 15 365.175 20.732 302.339 18.868 233.417 32.886 134.507 32.818 77.891 41.104 45 36.479 72.893 289.747 87.746 450.766 124.797 484.116 216.129 475.691 255.057 472.1 337.037 427.77 391.687 422.729 456 391.448 445.895 249.212 431.234 57.534"
          >
            <animate
              id="to-1B"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="45 36.479 72.893 289.747 87.746 450.766 124.797 484.116 216.129 475.691 255.057 472.1 337.037 427.77 391.687 422.729 456 391.448 445.895 249.212 431.234 57.534 427.31 15 365.175 20.732 302.339 18.868 233.417 32.886 134.507 32.818 77.891 41.104"
            />
            <animate
              id="to-2B"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="18 44.875 38.39 128.025 67.306 186.98 90.781 279.47 140.073 376.301 167.3 439.543 199.804 466.201 355.234 402.037 470.222 357.081 481.42 308.955 441.197 206.073 424.053 162.223 360.433 76.267 326.661 37.096 276.938 34.65 176.562 39.66 48.374 33"
            />
            <animate
              id="to-3B"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="84.355 122.415 59.531 282.645 48.683 342.942 42 419.94 240.088 445.16 304.015 455.065 381.726 472.574 435.026 478.644 444.127 419.894 442.112 372.546 457.006 276.409 445.858 227.647 452.135 162.988 458.742 96.194 457.897 65.435 125.317 27.035 86.989 21"
            />
            <animate
              id="to-4B"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="250.017 28 183.969 38.603 78 50 66 98.246 80.001 249 90.578 300.112 97.131 440.043 102.046 472.159 235.001 462.001 398.606 458.395 434 415 421.543 351.727 421.543 284.055 418.501 227.579 421.543 179.681 422.863 114.692 418.267 32.867"
            />
            <animate
              id="to-5B"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="200ms"
              to="352.324879 41.764 265 38.159 101.394 41.764 66 85.16 60.8181978 155.600171 66 284.055 77.137 385.467 77.137 442.497903 316.031 461.556 421.543 450.818359 434 401.913 434 350.986328 434 284.055 434 226.5 434 186.777511 416.019531 121.058594 402.869 60.116"
            />
          </polygon>
        </clipPath>
      </defs>
      <g className={styles.takeawayPhoto}>
        <g
          id="photo"
          stroke="none"
          fill="none"
          clipPath="url(#photo-clip)"
          style={{
            transform: shapeConfigs[shapeIndex].transforms.photo,
            transformOrigin: "center center",
            transition: "transform .3s ease-out",
          }}
        >
          {shapeConfigs.map((config, i) => {
            return (
              <image
                key={`shape-img-${i}`}
                width="500"
                height="500"
                x="0"
                y="0"
                href={config.photo.url}
                style={{ opacity: 0, transition: "opacity .2s ease-out" }}
                id={`takeaway-photo-${i + 1}`}
              />
            )
          })}
        </g>
      </g>
      <g className={styles.takeawayColor}>
        <g
          id="color"
          stroke="none"
          fill="none"
          clipPath="url(#color-clip)"
          style={{
            mixBlendMode: "difference",
            transition: "transform .3s ease-out, color .2s ease-in-out",
            transformOrigin: "center center",
            transform: shapeConfigs[shapeIndex].transforms.color,
            color: shapeConfigs[shapeIndex].color,
          }}
        >
          <rect width="500" height="500" x="0" y="0" fill="currentColor" />
        </g>
      </g>
    </svg>
  )
}
